import React, { useState, useEffect } from 'react';

export default function Youtube(props) {
    const url = props.src;

    const [video, setVideo] = useState("")

    const aspectRatio = 9 / 16; 

    const videostyle = {
      position: 'relative',
      width: '100%',
      paddingBottom: `${aspectRatio * 100}%`,
    };
  
    const iframeStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };


    useEffect(() => {    
      setVideo(url)
    }, []);

    return (
      <div style={videostyle}>
        {video != "" && 
        <iframe
          style={iframeStyle}
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>}
      </div>
    );
  };
