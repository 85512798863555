import React, { useState, useEffect } from 'react';
import ContinueButton from './continuebutton';
import correctsound from '../assets/sounds/correct.mp3';
import Stack from '@mui/material/Stack';
import styles from './video.module.css';
import Youtube from '../util/youtube.js'

export default function Video(props) {
    const color = props.color;
    const exercisefield = props.exercisefield
    const video = exercisefield.image
    const [correctaudio, setCorrectAudio] = useState();
    const soundenabled = props.soundenabled;

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
        if (correctaudio != null) {
            correctaudio.play();
        }
    }

    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            increaseOrder()
          }
        };
    
        // Add the event listener to the window or document
        window.addEventListener('keydown', handleKeyDown);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [correctaudio]);

    return (
        <Stack className={styles.videostack} spacing={15}>
            <div>&nbsp;</div>
            <Youtube src={video} />
            <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
}
