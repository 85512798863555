import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ContinueButton from './continuebutton';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import styles from './newgame.module.css';

export default function NewGame(props) {
    const color = props.color;
    const lesson = props.lesson;
    const lessontype = props.lessontype;
    const [correctaudio, setCorrectAudio] = useState();
    const soundenabled = props.soundenabled;

    let lessontypename = ""
    let lessonimage = ""

    switch(lessontype) {
        case 1: 
            lessontypename = 'Kelimeler'
            lessonimage = 'vocabularylesson'
            break
        case 2: 
            lessontypename = 'Dilbilgisi'
            lessonimage = 'grammarlesson'
            break
        case 3: 
            lessontypename = 'Konuşmalar'
            lessonimage = 'dialoguelesson'
            break
        case 4: 
            lessontypename = 'Video'
            lessonimage = 'videolesson'
            break
        default: 
            lessontypename = ''
            break
    }

    function increaseOrder() {
        props.onNextExercise(0);
        if (correctaudio != null) {
            correctaudio.play();
        }
    }

    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
          }

        // const handleKeyDown = (event) => {
        //   if (event.key === 'Enter') {
        //     increaseOrder()
        //   }
        // };
    
        // window.addEventListener('keydown', handleKeyDown);

        // return () => {
        //   window.removeEventListener('keydown', handleKeyDown);
        // };
      }, []);

    return(
        <Stack sx={{alignItems: 'center', width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} spacing={4}>
            <h2 className={styles.lessontitle}>{lessontypename}</h2>
        <Box sx={{
            height: 450,
            width: 500,
            backgroundColor: 'primary',
            display: 'flex',
            justifyContent: 'center'
          }}>
        <Stack sx={{ alignItems: 'center', mt: 10}} spacing={8}>
            <img src={require('../assets/icons/' + lessonimage + '.png')} width="300">
            </img> 
                <h3>{lesson.explanation}</h3>
        </Stack>
          </Box>
          <ContinueButton color={color} onClick={increaseOrder} />
          </Stack>
    )
}