import { useCallback, useState, useEffect } from "react";
import AuthContext from "./authcontext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import firebaseApp from './firebase'
import { Languages } from '../util/constants';

const AuthContextProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [language, setLanguage] = useState({languagecode: localStorage.getItem('Language_Code'), sourcelanguagecode: localStorage.getItem('Source_Language_Code')});
  const [user, setUser] = useState({});
  const [login, setLogin] = useState(false);
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  const navigate = useNavigate();

  useEffect(() => {

    if (language?.languagecode != null && language?.sourcelanguagecode != null) {
      let languagecode = language?.languagecode
      let sourcelanguagecode = language?.sourcelanguagecode
      if (Languages.some(x => x.code === languagecode) && Languages.some(x => x.sourcelanguagecode === sourcelanguagecode)) {
        localStorage.setItem("Language_Code", language?.languagecode);
        localStorage.setItem("Source_Language_Code", language?.sourcelanguagecode);
      }
      else {
        localStorage.setItem("Language_Code", "en");
        localStorage.setItem("Source_Language_Code", "tr");
      }
    }

    const unsubscribe = onAuthStateChanged(auth, (authuser) => {
        if (authuser) {
            setIsInitialized(true)
            getUser(authuser);
            navigate("/");
        } else {    
            setIsInitialized(true);
            setUser(null);
            setLogin(false);
            navigate("/login");
        }
        });
    return () => { 
      unsubscribe() 
    };
  }, [language, login, isLoaded]);

  useEffect(() => {
    var languagecode = language?.languagecode;
    var sourcelanguagecode = language?.sourcelanguagecode;

    if (languagecode != null && sourcelanguagecode != null) {
      if (sourcelanguagecode == languagecode) {
        if (sourcelanguagecode == "tr") {
          languagecode = "en"
        }
        if (sourcelanguagecode == "en") {
          languagecode = "tr"
        }
      }
  
      setLanguage({languagecode: languagecode, sourcelanguagecode: sourcelanguagecode});  
    }
    else {
      sourcelanguagecode = "tr"
      languagecode = "en"

      setLanguage({languagecode: languagecode, sourcelanguagecode: sourcelanguagecode});
    }
  }, []);

  const getUser = async(authuser) => {
    const userid = authuser.uid
    const docRef = doc(db, "users", userid);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
        let profile = await getProfile(authuser)
        setUser({user: docSnap.data(), profile: profile});
        setIsLoaded(true)
    }
    else {
      const newuser = {   
        "expirationdate": 0,         
        "languagecode": language.languagecode,
        "premium": false,
        "purchasechannel": "",
        "sourcelanguagecode": language.sourcelanguagecode,
        "userid": userid
      }

      await setDoc(docRef, newuser);

      let profile = await getProfile(authuser)
      setUser({user: newuser, profile: profile});
      setIsLoaded(true)
    }
  }

  const getProfile = async(authuser) => {
    var languagecode = language?.languagecode;

    const docRef = doc(db, "users", authuser.uid, "profiles", languagecode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        createProfile(authuser, languagecode);

        let newprofile = {
          datesofactivity: [],
          finishedlessons: [],
          languagecode: language.languagecode,
          lifelossdates: [],
          percentage: 0.0,
          vocabulary: 0
          };

        return newprofile
    }
  };

  const createProfile = async(authuser, languagecode) => {
    const docRef = doc(db, "users", authuser.uid, "profiles", languagecode);
    const data = {
      datesofactivity: [],
      finishedlessons: [],
      languagecode: language.languagecode,
      lifelossdates: [],
      percentage: 0.0,
      vocabulary: 0
      };
    await setDoc(docRef, data);
  }

  const getContent = useCallback(() => {
    if (!isInitialized) {
      return <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={false}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    }
    return children;
  }, [isInitialized, user, children]);

  return <AuthContext.Provider value={{uservalue: [user, setUser], languagevalue: [language, setLanguage], loaded: isLoaded, togglelogin: [login, setLogin]}}>{getContent()}</AuthContext.Provider>;
};

export default AuthContextProvider;