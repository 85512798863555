import React, { useState, useEffect, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Colors, DarkColors, LightColors, TintColor, OtherColors, OtherColorsDark, MAX_LIVES, LIVES_LOCK_S, TOTAL_POINTS } from '../util/constants';
import Explanation from '../components/explanation';
import Fill from '../components/fill';
import MultipleChoice from '../components/multiplechoice';
import Match from '../components/match';
import Table from '../components/table';
import SelectImageDouble from '../components/selectimagedouble';
import SelectImageQuadrupole from '../components/selectimagequadrupole';
import Dialogue from '../components/dialogue';
import DialogueFill from '../components/dialoguefill';
import Examples from '../components/examples';
import Vocabulary from '../components/vocabulary';
import { useNavigate, useLocation } from "react-router-dom";
import { getFirestore, collection, getDocs, query, where, doc, updateDoc, arrayUnion, orderBy } from "firebase/firestore";
import firebaseApp from '../util/firebase';
import AuthContext from "../util/authcontext";
import GamePanel from '../components/gamepanel';
import ExitButton from '../components/exitbutton';
import NoLives from '../components/nolives';
import EndOfLesson from '../components/endoflesson';
import NewGame from '../components/newgame';
import Video from '../components/video';
import Listen from '../components/listen';

const theme = createTheme({
    palette: {
        primary: {
            main: Colors[0],
            dark: DarkColors[0],
            light: LightColors[0],
        },
        secondary: {
            main: Colors[1],
            dark: DarkColors[1],
            light: LightColors[0],
        },
        error: {
            main: Colors[2],
            dark: DarkColors[2],
            light: LightColors[0],
        },
        warning: {
            main: Colors[3],
            dark: DarkColors[3],
            light: LightColors[0],
        },
        success: {
            main: OtherColors[1],
            dark: OtherColorsDark[1],
            light: LightColors[0]
        },
        info: {
            main: TintColor
        }
    },
});

export default function Game() {
    const { uservalue, languagevalue, loaded } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const [language, setLanguage] = languagevalue
    const languagecode = language.languagecode
    const { state } = useLocation();
    const lesson = state.lesson;
    const lessontype = state.lessontype;
    const [exerciseorder, setExerciseOrder] = useState(0);
    const [index, setIndex] = useState(0);
    const [palette, setPalette] = useState('primary');
    const [exercisearray, setExerciseArray] = useState([])
    const [exercisefields, setExerciseFields] = useState([]);
    const [exercisefield, setExerciseField] = useState({});
    const [currentexercisefields, setCurrentExerciseFields] = useState([]);
    const [remainingexercises, setRemainingExercises] = useState([]);
    const [nextexercise, setNextExercise] = useState("");
    const [exercisecounter, setExerciseCounter] = useState(0);
    const [user, setUser] = uservalue;
    const [premium, setPremium] = useState(false)
    const [lives, setLives] = useState(MAX_LIVES)

    const navigate = useNavigate();
    const soundenabled = (localStorage.getItem("Sound_Enabled") ?? "true") === "true" ? true : false;

    function goToNextExercise(neworder, iswrong = false, exercise = null) {

        var _profile = user?.profile;
        var _user = user?.user

        let remaininglives = lives

        if (!iswrong && !premium) {
            const newlives = calculateLives(_profile);

            if (newlives != lives) {
                remaininglives = newlives
                setLives(newlives)
                setUser({ user: _user, profile: _profile });
                updateProfile(_user.userid, _profile);
            }
        }

        if (iswrong) {
            if (exercise != null) {
                let _remainingexercises = remainingexercises;

                _remainingexercises.push({ exercise: exercise, exercisefield: exercisefield, fields: currentexercisefields, order: neworder - 1});
                setRemainingExercises(_remainingexercises);
            }

            if (!premium) {
                if (lives > 0) {
                    remaininglives -= 1
                    setLives(remaininglives);
                }

                let currenttime = new Date().getTime()
                const wrongdate = Math.floor(currenttime / 1000)

                if (_profile?.lifelossdates.length >= MAX_LIVES) {
                    _profile?.lifelossdates.unshift(wrongdate)
                    _profile?.lifelossdates.splice(MAX_LIVES)
                }
                else {
                    _profile?.lifelossdates.push(wrongdate)
                }

                setUser({ user: _user, profile: _profile });
                updateProfile(_user.userid, _profile)
            }
        }

        let nextexercise = "";
        let currentexercisefield = null;
        let currentfields = [];

        if (exercisefields.length !== 0 && index > exercisefields.length - 1 && nextexercise != "endoflesson" && neworder < 1001) {
            nextexercise = "endoflesson"
            neworder = 1000
        }

        if (remaininglives == 0 && !premium) {
            nextexercise = "nolives";
        }
        else {
            if (neworder == 0) {
                neworder = 1
            }

            let decidedexercise = decideNextExercise(neworder, exercisefields)
            nextexercise = decidedexercise.nextexercise
            neworder = decidedexercise.neworder
            currentexercisefield = decidedexercise.currentexercisefield
            currentfields = decidedexercise.currentexercisefields
        }

        if (neworder == 1001) {
            const finishedlessons = _profile?.finishedlessons;
            const lessonid = lesson.id;

            if (!finishedlessons.includes(lessonid)) {
                _profile.percentage += lesson.points * 100.0 / TOTAL_POINTS[languagecode];
                _profile.vocabulary += lesson.vocabularycount;
                _profile.finishedlessons.push(lessonid);

                updateProfile(_user.userid, _profile, true);
            }
        }

        setExerciseOrder(neworder);
        setExerciseField(currentexercisefield)
        setCurrentExerciseFields(currentfields)
        setNextExercise(nextexercise);
        setExerciseCounter(exercisecounter + 1);
    }

    const updateProfile = async (userid, profile, islast = false) => {
        const docRef = doc(db, "users", userid, "profiles", languagecode);

        if (islast) {
            const currenttime = new Date().getTime()
            const currentdate = Math.floor(currenttime / (1000 * 3600 * 24))
            await updateDoc(docRef, {
                finishedlessons: arrayUnion(lesson.id),
                percentage: profile.percentage,
                vocabulary: profile.vocabulary,
                datesofactivity: arrayUnion(currentdate)
            });
        }
        else {
            await updateDoc(docRef, {
                lifelossdates: profile?.lifelossdates
            });
        }
    }

    function calculateLives(profile) {

        const lifelossdates = profile?.lifelossdates

        let currenttime = new Date().getTime()
        const currenttimeseconds = Math.floor(currenttime / 1000)

        var lives = MAX_LIVES

        if (lifelossdates != null && lifelossdates.length > 0) {
            lifelossdates.forEach(function (losstime) {
                let timedifference = currenttimeseconds - losstime

                if (timedifference < LIVES_LOCK_S) {
                    lives -= 1
                }
            })
        }

        return lives
    }

    function decideNextExercise(neworder, exercisefields) {
        let nextexercise = ""
        let currentexercisefield = {}
        let currentexercisefields = []

        if (lessontype == 1) {
            if (neworder > 1001) {
                navigate("/", { replace: true });
            }
            else if (neworder == 1000) {
                if (remainingexercises.length > 0) {
                    ({ nextexercise, currentexercisefield, currentexercisefields, neworder } = getRemainingExercise(nextexercise, currentexercisefield, currentexercisefields));
                }
                else {
                    nextexercise = "endoflesson";
                    neworder = 1001;
                }
            }
            else {
                if (neworder > exercisearray.length && exercisearray.length > 0) {
                    neworder = 1000
                    if (remainingexercises.length > 0) {
                        ({ nextexercise, currentexercisefield, currentexercisefields, neworder } = getRemainingExercise(nextexercise, currentexercisefield, currentexercisefields));
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1001;
                    }
                }
                else {
                    const nextexerciseobject = exercisearray[neworder - 1]
                    const currentfields = nextexerciseobject.fields
                    nextexercise = nextexerciseobject.exercise
                    currentexercisefield = exercisefields[nextexerciseobject.fieldindex]
                    currentexercisefields = currentfields
                    const newindex = currentexercisefield.order - 1
                    setIndex(newindex)
                }
            }
        }
        else if (lessontype == 2) {
            if (neworder > 1001) {
                navigate("/", { replace: true });
            }
            else if (neworder == 1000) {
                if (remainingexercises.length > 0) {
                    ({ nextexercise, currentexercisefield, currentexercisefields, neworder } = getRemainingExercise(nextexercise, currentexercisefield, currentexercisefields));
                }
                else {
                    nextexercise = "endoflesson";
                    neworder = 1001;
                }
            }
            else {
                if (neworder > exercisearray.length && exercisearray.length > 0) {
                    neworder = 1000
                    if (remainingexercises.length > 0) {
                        ({ nextexercise, currentexercisefield, currentexercisefields, neworder } = getRemainingExercise(nextexercise, currentexercisefield, currentexercisefields));
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1001;
                    }
                }
                else {
                    const nextexerciseobject = exercisearray[neworder - 1]
                    const currentfields = nextexerciseobject.fields
                    nextexercise = nextexerciseobject.exercise
                    currentexercisefield = exercisefields[nextexerciseobject.fieldindex]
                    currentexercisefields = currentfields
                    const newindex = currentexercisefield.order - 1
                    setIndex(newindex)
                }
            }
        }
        else if (lessontype == 3) {
            switch (neworder) {
                case 1:
                    nextexercise = "dialogue";
                    currentexercisefield = exercisefields[0]
                    currentexercisefields = exercisefields
                    setIndex(0)
                    break;
                case 2:
                    nextexercise = "dialoguefill";
                    currentexercisefield = exercisefields[index]
                    setIndex(exercisefields.length)
                    break;
                case 1000:
                    nextexercise = "endoflesson"
                    neworder = 1001
                    break
                default:
                    navigate("/", { replace: true });
                    break;
            }
        }
        else if (lessontype == 4) {
            switch (neworder) {
                case 1:
                    nextexercise = "video"
                    currentexercisefield = exercisefields[0]
                    setIndex(1)
                    break
                case 1000:
                    nextexercise = "endoflesson"
                    neworder = 1001
                    break
                default:
                    navigate("/", { replace: true });
                    break;
            }
        }

        return { nextexercise, neworder, currentexercisefield, currentexercisefields }
    }

    function getRemainingExercise(nextexercise, currentexercisefield, currentexercisefields) {
        const remainingexercise = remainingexercises.shift();
        nextexercise = remainingexercise.exercise;
        currentexercisefield = remainingexercise.exercisefield;
        currentexercisefields = remainingexercise.fields;
        return { nextexercise, currentexercisefield, currentexercisefields, neworder: 1000 };
    }

    function buildExercises(exercisefields) {
        let availableexercises = []
        let exercisearray = []

        if (lessontype == 1) {
            availableexercises = ["selectimagedouble", "selectimagequadrupole", "listen", "match"]

            exercisefields.map((field, index) => {
                if (index == 0) {
                    exercisearray.push({ exercise: "vocabulary", fieldindex: index, fields: [] })
                    exercisearray.push({ exercise: "selectimagedouble", fieldindex: index, fields: exercisefields })
                }
                else if (index == 1) {
                    exercisearray.push({ exercise: "vocabulary", fieldindex: index, fields: [] })
                    exercisearray.push({ exercise: "listen", fieldindex: index, fields: exercisefields })
                    exercisearray.push({ exercise: "selectimagedouble", fieldindex: index - 1, fields: exercisefields })
                }
                else if (index == 2) {
                    exercisearray.push({ exercise: "vocabulary", fieldindex: index, fields: [] })
                    exercisearray.push({ exercise: "selectimagequadrupole", fieldindex: index, fields: exercisefields })
                    exercisearray.push({ exercise: "selectimagedouble", fieldindex: index - 2, fields: exercisefields })
                    exercisearray.push({ exercise: "listen", fieldindex: index, fields: exercisefields })
                }
                else if (index == 3) {
                    exercisearray.push({ exercise: "vocabulary", fieldindex: index, fields: [] })
                    exercisearray.push({ exercise: "match", fieldindex: index, fields: exercisefields.filter(x => x.order <= index + 1) })
                    exercisearray.push({ exercise: "selectimagequadrupole", fieldindex: index - 1, fields: exercisefields })
                    exercisearray.push({ exercise: "selectimagedouble", fieldindex: index - 2, fields: exercisefields })
                }
                else {
                    exercisearray.push({ exercise: "vocabulary", fieldindex: index, fields: [] })
                    let shuffledavailableexercises = availableexercises.sort(() => Math.random() - 0.5);
                    exercisearray.push({ exercise: shuffledavailableexercises[0], fieldindex: index, fields: shuffledavailableexercises[0] == "match" ? exercisefields.filter(x => x.order <= index + 1) : exercisefields })
                    exercisearray.push({ exercise: shuffledavailableexercises[1], fieldindex: index - 3, fields: shuffledavailableexercises[1] == "match" ? exercisefields.filter(x => x.order <= index + 1) : exercisefields })
                    exercisearray.push({ exercise: shuffledavailableexercises[2], fieldindex: index - 1, fields: shuffledavailableexercises[2] == "match" ? exercisefields.filter(x => x.order <= index + 1) : exercisefields })
                    exercisearray.push({ exercise: shuffledavailableexercises[3], fieldindex: index - 2, fields: shuffledavailableexercises[3] == "match" ? exercisefields.filter(x => x.order <= index + 1) : exercisefields })
                }
            })
        }
        else if (lessontype == 2) {
            let exercise = ""
            let fieldindex = 0
            let lasttype = 0
            let lastorder = 0
            availableexercises = ["fill", "multiplechoice", "listen"]
            let currentexamples = []
            exercisefields.map((field, index) => {
                let type = 0
                let fields = []

                if (field.translation == "" && field.type === 2) {
                    type = 2
                    exercise = "explanation"
                    fieldindex = index
                    fields = exercisefields.filter(x => x.order == lastorder + 1)
                }
                else if (field.order == lastorder) {
                    if (field.type !== 10) {
                        type = 2
                        if (exercise == "explanation") {
                            exercise = "table"
                            fieldindex += 1
                        }
                        else if (exercise !== "") {
                            let availableexercisesexcluded = availableexercises.filter(x => x !== exercise)
                            let randomindex = Math.floor(Math.random() * 2)
                            exercise = availableexercisesexcluded[randomindex]
                            fieldindex += 1
                        }
                        fields = exercisefields.filter(x => x.order == lastorder && x.translation != "")
                    }
                    else {
                        exercise = ""
                        fieldindex += 1
                    }
                }
                else if ((lasttype == 2 || lastorder == 0) && field.type == 3) {
                    type = 3
                    exercise = "examples"
                    let nextexplanationexercises = exercisefields.filter(x => x.type == 2 && x.order > field.order).sort((a, b) => a.order - b.order)
                    fields = nextexplanationexercises.length > 0 ? exercisefields.filter(x => x.type == 3 && x.order >= field.order && x.order < nextexplanationexercises[0].order) : exercisefields.filter(x => x.type == 3 && x.order >= field.order) 
                    currentexamples = fields
                }
                else {
                    type = 3
                    let availableexercisesexcluded = availableexercises.filter(x => x !== exercise)
                    let randomindex = Math.floor(Math.random() * 2)
                    exercise = availableexercisesexcluded[randomindex]
                    fields = currentexamples.filter(x => x.translation !== "")
                    fieldindex += 1
                    if (exercisefields[fieldindex].translation === "") {
                        fieldindex += 1
                    }
                }

                if (exercise !== "") {
                    exercisearray.push({ exercise: exercise, fieldindex: fieldindex, fields: fields })
                    lasttype = type
                    lastorder = field.order
                }
            })
        }

        setExerciseFields(exercisefields);
        setExerciseArray(exercisearray)
    }

    function exit() {
        navigate("/");
    }

    useEffect(() => {
        const fetchData = async () => {
            const documentname = "fields" + languagecode + language?.sourcelanguagecode
            const q = query(collection(db, documentname), where("lessonid", "==", lesson.id), orderBy("order"), orderBy("group"));
            const querySnapshot = await getDocs(q);
            const result = querySnapshot.docs.map(doc => doc.data());
            buildExercises(result)
        }

        fetchData();

        return () => {
            setExerciseOrder(0)
        }
    }, []);

    useEffect(() => {
        if (user?.user && user?.profile && loaded) {
            const _profile = user.profile
            const _user = user.user

            if (_user?.premium) {
                setPremium(true)
            }
            else {
                setLives(calculateLives(_profile))
            }
        }
    }, [user, loaded]);

    useEffect(() => {
        if (exerciseorder > 1000) {
            setPalette('success');
            document.body.style.background = theme.palette.success.main;
        }
        else if (nextexercise === "nolives") {
            document.body.style.background = OtherColors[0];
        }
        else {
            switch (exerciseorder % 4) {
                case 0:
                    setPalette('primary');
                    document.body.style.background = theme.palette.primary.main;
                    break;
                case 1:
                    setPalette('secondary');
                    document.body.style.background = theme.palette.secondary.main;
                    break;
                case 2:
                    setPalette('error');
                    document.body.style.background = theme.palette.error.main;
                    break;
                case 3:
                    setPalette('warning');
                    document.body.style.background = theme.palette.warning.main;
                    break;
                default:
                    setPalette('success');
                    document.body.style.background = theme.palette.success.main;
            }
        }
    }, [nextexercise]);

    if (nextexercise === "nolives") {
        return (
            <>
                {loaded &&
                    <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                        <NoLives profile={user?.profile} soundenabled={soundenabled} order={exerciseorder} onNextExercise={goToNextExercise} exit={exit} />
                    </Container>}
            </>
        )
    }
    else if (exerciseorder === 0) {
        return (
            <>
                {loaded &&
                    <ThemeProvider theme={theme}>
                        <ExitButton color={palette + '.dark'} onExit={exit} />
                        <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                            <NewGame color={palette} lesson={lesson} lessontype={lessontype} order={exerciseorder} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                        </Container>
                    </ThemeProvider>
                }</>
        )
    }
    else if (nextexercise === "vocabulary") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Vocabulary languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }
    else if (nextexercise === "selectimagedouble") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <SelectImageDouble exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={exercisefields.filter(x => x.type == 1)} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }
    else if (nextexercise === "selectimagequadrupole") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <SelectImageQuadrupole exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={exercisefields.filter(x => x.type == 1)} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "match") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Match exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={exercisefields.filter(x => x.type == 1)} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "explanation") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Explanation languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={currentexercisefields} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "fill") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield && exercisecounter && 
                        <Fill exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} exercisefields={currentexercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "multiplechoice") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <MultipleChoice exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} exercisefields={currentexercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "table") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Table languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} exercisefields={currentexercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "dialogue") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Dialogue languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={currentexercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "dialoguefill") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <DialogueFill languagecode={languagecode} color={palette} order={exerciseorder} exercisefields={exercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "listen") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Listen exercisecounter={exercisecounter} languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "examples") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Examples languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} exercisefields={currentexercisefields} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }


    else if (nextexercise === "video") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={lives} premium={premium} order={exerciseorder} exercisearray={exercisearray} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    {exercisefield &&
                        <Video languagecode={languagecode} color={palette} order={exerciseorder} exercisefield={exercisefield} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    }
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "endoflesson") {
        return (
            <ThemeProvider theme={theme}>
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="game-container">
                    <EndOfLesson user={user} exercisefields={exercisefields} order={exerciseorder} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else {
        return null;
    }
}