import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import Container from '@mui/material/Container';
import AuthContext from "../util/authcontext";
import { BackGroundColors } from '../util/constants';

export default function Hep() {

    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;

    useEffect(() => {
        document.body.style.background = BackGroundColors[0]
    }, []);

    return(
        <>
        {user?.profile != null && <><NavBar languagecode={language?.languagecode} points={user?.profile?.points} percentage={user?.profile?.percentage} avatarurl={user?.authuser?.photoURL} /></>}
        <Container sx={{marginTop: '100px'}}>
        <h1>Sorularınız için bize fono@fono.com.tr e-posta adresinden ulaşabilirsiniz.</h1>
        </Container>
        </>
    )
}