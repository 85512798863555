import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './nolives.module.css'
import { Colors, LightColors } from '../util/constants';
import CountDownTimer from './countdowntimer';
import { LIVES_LOCK_S } from '../util/constants';

export default function NoLives(props) {

    const profile = props.profile;
    const [time, setTime] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const time = Math.min(...profile.lifelossdates)
        setTime(time);
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
    }

    function goToActivation() {
        navigate("/activation");
    }

    return(
        <Stack spacing={25} sx={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop:'64px'}}>
        <h2>Hiç canın kalmadı</h2>
        <Stack spacing={5} sx={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
            <h3>Sonraki ❤️:</h3>
            <CountDownTimer time={time} locktime={LIVES_LOCK_S} onFinish={increaseOrder} />
        </Stack>
        <Stack spacing={3}>
        <Button className={styles.continuebutton} onClick={goToActivation} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 500, height: 50, ':hover': {
            bgcolor: LightColors[0]
          }}}>Aktivasyon</Button>   
        <Button className={styles.continuebutton} onClick={props.exit} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 500, height: 50, ':hover': {
            bgcolor: LightColors[0]
          }}}>Devam</Button>
        </Stack>
        </Stack>
    )
}