import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NavBar from '../components/navbar';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import AuthContext from "../util/authcontext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BADGE_THRESHOLDS, Colors, BackGroundColors, LightColors } from '../util/constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import premiumicon from '../assets/icons/premiumicon.svg'
import styles from './profile.module.css';

export default function Profile() {

    const navigate = useNavigate();
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [premium, setPremium] = useState(false);
    const [totaldays, setTotalDays] = useState(null);
    const [streak, setStreak] = useState(null)
    const [finishedlessonscount, setFinishedLessonsCount] = useState(null)
    const [percentages, setPercentages] = useState(null);
    const auth = getAuth();
    const [username, setUserName] = useState("")
    const avatarurl = auth?.currentUser?.photoURL

    function toggleSignIn() {
        if (auth?.currentUser) {
            auth.signOut();
        }
        navigate("/login");
    }

    function togglePremium() {
        if (premium == false) {
            navigate("/activation");
        }
    }

    function calculateStreak(datesofactivity) {
        let count = 0;

        if (datesofactivity && datesofactivity.length > 0) {
            for (let i = datesofactivity.length - 1; i > 0; i--) {
                if (datesofactivity[i] === datesofactivity[i - 1] + 1) {
                  count++;
                }
                else {
                    return count 
                }
              }
        }

        return count 
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authuser) => {
            if (authuser) {
                const username = authuser.email;
                setUserName(username)
            } else {    
                navigate("/login");
            }
            });
        return () => { 
          unsubscribe() 
        };
      }, []);

    useEffect(() => {
        const datesofactivity = user?.profile?.datesofactivity
        const percentage = user?.profile?.percentage;
        const totaldays = datesofactivity ? datesofactivity.length : 0
        const streak = calculateStreak(datesofactivity)
        const finishedlessonscount = user?.profile?.finishedlessons.length
        const premium = user?.user?.premium

        setTotalDays(totaldays)
        setStreak(streak)
        setFinishedLessonsCount(finishedlessonscount)
        setPremium(premium)

        if (percentage <= BADGE_THRESHOLDS[0]) {
            const progress = (percentage * 100) / BADGE_THRESHOLDS[0]
            setPercentages({percentage1: progress, percentage2: 0.0, percentage3: 0.0, percentage4: 0.0})
        }
        else if (percentage > BADGE_THRESHOLDS[0] && percentage <= BADGE_THRESHOLDS[1]) {
            const progress = ((percentage - BADGE_THRESHOLDS[0]) * 100) / (BADGE_THRESHOLDS[1] - BADGE_THRESHOLDS[0]) 
            setPercentages({percentage1: 100.0, percentage2: progress, percentage3: 0.0, percentage4: 0.0}) 
        }
        else if (percentage > BADGE_THRESHOLDS[1] && percentage <= BADGE_THRESHOLDS[2]) {
            const progress = ((percentage - BADGE_THRESHOLDS[1]) * 100) / (BADGE_THRESHOLDS[2] - BADGE_THRESHOLDS[1])
            setPercentages({percentage1: 100.0, percentage2: 100.0, percentage3: progress, percentage4: 0.0}) 
        }
        else if (BADGE_THRESHOLDS[2] > 50.0) {
            const progress = ((percentage - BADGE_THRESHOLDS[2]) * 100) / (BADGE_THRESHOLDS[3] - BADGE_THRESHOLDS[2])
            setPercentages({percentage1: 100.0, percentage2: 100.0, percentage3: 100.0, percentage4: progress}) 
        }

    }, [user]);

    useEffect(() => {
        document.body.style.background = BackGroundColors[0]
    }, []);

    return(
        <>
        {user?.profile && user?.user && language && <><NavBar points={user?.profile?.points} vocabulary={user?.profile?.vocabulary} percentage={user?.profile?.percentage} languagecode={language?.languagecode} avatarurl={avatarurl ?? ""} />
        <Container sx={{ display:'flex', justifyContent: 'center'}}>
            <Stack spacing={6} sx={{width: '80%', justifyContent: 'center'}}>
            {username &&
            <Stack spacing={4} sx={{alignItems: 'center', marginTop: '100px', paddingLeft: '16px'}}>
                <Avatar alt="Profile photo" src={avatarurl ?? ""} sx={{width: '60px', height: '60px'}} />
                <Stack spacing={0}>
                <h2>{username}</h2>
                <Button onClick={toggleSignIn} sx={{textTransform: 'none', fontSize: '0.95rem', fontWeight: 'bold'}}>Çıkış</Button>
                </Stack>
            </Stack>}
            {premium && 
            <Box sx={{ backgroundColor: '#C987FF', display:'flex', alignItems: 'center', padding: 1, height: 100, borderRadius: 1, marginTop: 5, cursor: 'pointer'}} onClick={togglePremium}>
                <Stack direction="row" spacing={4} sx={{alignItems: 'center', paddingLeft: '16px'}}>
                    <img alt="Premium" src={premiumicon} width= '60px' height= '60px'></img>
                    <Stack spacing={0}>
                        <h2>{premium ? "Premium" : "Premium'a yükselt"}</h2>
                    </Stack>
                    <h4>{premium ? "Bitiş tarihi: " +  new Date(user?.user.expirationdate * 1000).toLocaleString() : ""}</h4>
                </Stack>
            </Box>}
            <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                <Stack className={styles.statsbox} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2, backgroundColor: Colors[0], padding: 2, borderRadius: 1}}>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <h2>Toplam gün</h2>
                        <h3>{totaldays}</h3>
                    </Stack>
                </Stack>
                <Stack className={styles.statsbox} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2, backgroundColor: Colors[1], padding: 2, borderRadius: 1}}>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <h2>Üst üste</h2>
                        <h3>{streak}</h3>
                    </Stack>
                </Stack>
                <Stack className={styles.statsbox} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2, backgroundColor: Colors[2], padding: 2, borderRadius: 1}}>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <h2>Biten ders</h2>
                        <h3>{finishedlessonscount}</h3>
                    </Stack>
                </Stack>
            </Stack>
            {percentages &&
            <Box sx={{ backgroundColor: BackGroundColors[1], display:'flex', alignItems: 'center', padding: 1, borderRadius: 1, marginTop: 5}}>      
            <List>
                <ListItem
                    key="badgeitem1">
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgetraveler.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="1" primary="Turist" primaryTypographyProps={{fontSize: '24px', color: LightColors[1]}} />
                            <ListItemText id="11" secondary="Seviye" secondaryTypographyProps={{fontSize: '14px', color: LightColors[1]}} />
                            <LinearProgress variant="determinate" value={percentages.percentage1} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem2" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgenomad.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="2" primary="Göçebe" primaryTypographyProps={{fontSize: '24px', color: LightColors[1]}} />
                            <ListItemText id="21" secondary="Seviye" secondaryTypographyProps={{fontSize: '14px', color: LightColors[1]}} />
                            <LinearProgress variant="determinate" value={percentages.percentage2} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem3" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgeexpatriate.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="3" primary="Expat" primaryTypographyProps={{fontSize: '24px', color: LightColors[1]}} />
                            <ListItemText id="31" secondary="Seviye" secondaryTypographyProps={{fontSize: '14px', color: LightColors[1]}} />
                            <LinearProgress variant="determinate" value={percentages.percentage3} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem4" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgecitizen.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="4" primary="Vatandaş" primaryTypographyProps={{fontSize: '24px', color: LightColors[1]}} />
                            <ListItemText id="41" secondary="Seviye" secondaryTypographyProps={{fontSize: '14px', color: LightColors[1]}} />
                            <LinearProgress variant="determinate" value={percentages.percentage4} />
                        </Stack>
                </ListItem>
            </List>  
        </Box> }  
        </Stack>
    </Container></>
    }
    </>)
}