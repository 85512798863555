import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './endoflesson.module.css'
import ContinueButton from './continuebutton';
import CircularProgress from '@mui/material/CircularProgress';
import { BADGE_THRESHOLDS } from '../util/constants.js'

export default function EndOfLesson(props) {

    const user = props.user;

    const [percentage, setPercentage] = useState(0.0)
    const [vocabulary, setVocabulary] = useState()
    const [badge, setBadge] = useState({})

    useEffect(() => {
        const _percentage = user?.profile?.percentage
        const roundpercentage = _percentage ? (_percentage > 100.0 ? 100 : _percentage.toFixed(1)) : 0.0
        setPercentage(roundpercentage)
        setVocabulary(user?.profile?.vocabulary)

        if (roundpercentage < BADGE_THRESHOLDS[0]) {
            setBadge({name: 'Turist', image: require('../assets/icons/badgetraveler.png')})
        }
        else if (roundpercentage >= BADGE_THRESHOLDS[0] && roundpercentage < BADGE_THRESHOLDS[1]) {
            setBadge({name: 'Gezgin', image: require('../assets/icons/badgenomad.png')})
        }
        else if (roundpercentage >= BADGE_THRESHOLDS[1] && roundpercentage < BADGE_THRESHOLDS[2]) {
            setBadge({name: 'Expat', image: require('../assets/icons/badgeexpatriate.png')})
        }
        else if (roundpercentage >= BADGE_THRESHOLDS[2]) {
            setBadge({name: 'Vatandaş', image: require('../assets/icons/badgecitizen.png')})
        }
        else {
            setBadge({name: 'Turist', image: require('../assets/icons/badgetraveler.png')})
        }
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
    }

    return(
        <Stack spacing={10} sx={{ alignItems:'center', marginTop:'128px' }}>
        <Box className={styles.endoflessonbox} sx={{
            height: 600,
            width: 500,
            borderRadius: 1,
            backgroundColor: 'success.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'top'
          }}>
        <Stack justifyContent='space-between'>
        <Stack sx={{ textAlign: 'center'}} spacing={10}>
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                color: 'info.main',
                marginTop: '-55px'
                }}
                size={100}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                color: 'primary',
                marginTop: '-55px',
                position: 'absolute',
                animationDuration: '550ms',
                left: 106,
                zIndex: 2,
                }}
                size={100}
                thickness={4}
                value={percentage}
            />
            <img className={styles.badge} src={badge.image}></img>
        </Box>
            <h1 className={styles.endoflessontitle}>{badge.name}</h1>
        </Stack>
        <Stack direction="row" spacing={10}>
            <div>
        <h2 className={styles.endoflessontitle}>{vocabulary}</h2>
         <h3>Kelime Adedi</h3>   
         </div>
         <div>
         <h2 className={styles.endoflessontitle}>{percentage ? ('%' + percentage) : '%0.0'}</h2>
         <h3>Ders Yüzdesi</h3> 
         </div>
         </Stack>            
        </Stack>
        </Box>
        <ContinueButton color='success' onClick={increaseOrder} />
        </Stack>
    )
}