import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import styles from './selectimagequadrupole.module.css';
import { IMAGE_PATH, AUDIO_PATH } from '../util/constants';
import FloatingButton from './floatingbutton';
import Tooltip from '@mui/material/Tooltip';

export default function SelectImageQuadrupole(props) {
    const exercisefield = props.exercisefield
    const word = { name: exercisefield.name, translation: exercisefield.translation, order: exercisefield.order, image: exercisefield.image }
    const words = props.exercisefields.map(x => ({ name: x.name, translation: x.translation, order: x.order, image: x.image }));
    const color = props.color;
    const soundenabled = props.soundenabled;
    const languagecode = props.languagecode;
    const imagepath = IMAGE_PATH + languagecode + "-fono/"
    const audiopath = props.audio + word.audio
    const exercisecounter = props.exercisecounter

    const [allwords, setAllWords] = useState([]);
    const [rightindex, setRightIndex] = useState(-1);
    const [correctaudio, setCorrectAudio] = useState();
    const [incorrectaudio, setInCorrectAudio] = useState();
    const [itemclicked, setItemClicked] = useState(-1);
    const [showtranslations, setShowTranslations] = useState(false);

    function showTranslations() {
        setShowTranslations(!showtranslations)
    }

    useEffect(() => {
        const _otherwords = words.filter(_word => _word.order !== word.order && _word.image !== word.image);
        const _allwords = shuffle(_otherwords).slice(0, 3);
        const _rightindex = Math.floor(Math.random() * (_otherwords.length + 1)) % 4;
        _allwords.splice(_rightindex, 0, word);
        setAllWords(_allwords);
        setRightIndex(_rightindex);
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
            setInCorrectAudio(new Audio(incorrectsound));
        }

        return () => {
            setRightIndex(-1)
            setItemClicked(-1)
            setAllWords([])
        }
    }, [exercisefield, exercisecounter]);

    function increaseOrder(iswrong) {
        let order = props.order;
        order++;
        props.onNextExercise(order, iswrong, "selectimagedouble");
    }

    function checkAnswer(selectedindex) {
        if (selectedindex == rightindex) {
            if (correctaudio != null) {
                correctaudio.play();
            }
            increaseOrder();
        }
        else {
            if (incorrectaudio != null) {
                incorrectaudio.play();
            }
            setItemClicked(selectedindex);
            setTimeout(function () {
                increaseOrder(true);
            }, 600);
        }
    }

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        while (currentIndex != 0) {

            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === '1') {
                checkAnswer(0)
            }
            if (event.key === '2') {
                checkAnswer(1)
            }
            if (event.key === '3') {
                checkAnswer(2)
            }
            if (event.key === '4') {
                checkAnswer(3)
            }
            if (event.key === '5') {
                showTranslations()
            }
        };

        // Add the event listener to the window or document
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [rightindex]);

    return (
        <Stack spacing={12} sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2>Doğru resmi seç.</h2>
            <h1 className={styles.wordtext}>{exercisefield.name}</h1>
            <Stack spacing={4}>
                <Stack direction="row" spacing={4}>
                    {allwords.length > 0 && allwords[0].image && allwords[0].image !== "" &&
                        <Tooltip title={showtranslations ? "" : <h2 style={{ color: "lightblue" }}>{allwords[0].translation}</h2>}>
                            <Button className={styles.selectimagebutton + (itemclicked == 0 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(0, e)} sx={{
                                borderRadius: 2,
                                height: 192,
                                width: 192,
                                bgcolor: color + '.dark',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center', ':hover': {
                                    bgcolor: color + '.light'
                                }
                            }}>
                                <Stack>
                                    {showtranslations ?
                                        <h1 className={styles.wordtext}>{allwords[0].translation}</h1> :
                                        <img className={styles.selectimage} src={imagepath + allwords[0].image}>
                                        </img>}
                                </Stack>
                            </Button>
                        </Tooltip>
                    }
                    {allwords.length > 1 && allwords[1].image && allwords[1].image !== "" &&
                        <Tooltip title={showtranslations ? "" : <h2 style={{ color: "lightblue" }}>{allwords[1].translation}</h2>}>
                            <Button className={styles.selectimagebutton + (itemclicked == 1 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(1, e)} sx={{
                                borderRadius: 2,
                                height: 192,
                                width: 192,
                                bgcolor: color + '.dark',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center', ':hover': {
                                    bgcolor: color + '.light'
                                }
                            }}>
                                <Stack>
                                    {showtranslations ?
                                        <h1 className={styles.wordtext}>{allwords[1].translation}</h1> :
                                        <img className={styles.selectimage} src={imagepath + allwords[1].image}>
                                        </img>}
                                </Stack>
                            </Button>
                        </Tooltip>
                    }
                </Stack>
                <Stack direction="row" spacing={4}>
                    {allwords.length > 2 && allwords[2].image && allwords[2].image !== "" &&
                        <Tooltip title={showtranslations ? "" : <h2 style={{ color: "lightblue" }}>{allwords[2].translation}</h2>}>
                            <Button className={styles.selectimagebutton + (itemclicked == 2 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(2, e)} sx={{
                                borderRadius: 2,
                                height: 192,
                                width: 192,
                                bgcolor: color + '.dark',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center', ':hover': {
                                    bgcolor: color + '.light'
                                }
                            }}>
                                <Stack>
                                    {showtranslations ?
                                        <h1 className={styles.wordtext}>{allwords[2].translation}</h1> :
                                        <img className={styles.selectimage} src={imagepath + allwords[2].image}>
                                        </img>}
                                </Stack>
                            </Button>
                        </Tooltip>
                    }
                    {allwords.length > 3 && allwords[3].image && allwords[3].image !== "" &&
                        <Tooltip title={showtranslations ? "" : <h2 style={{ color: "lightblue" }}>{allwords[3].translation}</h2>}>
                            <Button className={styles.selectimagebutton + (itemclicked == 3 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(3, e)} sx={{
                                borderRadius: 2,
                                height: 192,
                                width: 192,
                                bgcolor: color + '.dark',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center', ':hover': {
                                    bgcolor: color + '.light'
                                }
                            }}>
                                <Stack>
                                    {showtranslations ?
                                        <h1 className={styles.wordtext}>{allwords[3].translation}</h1> :
                                        <img className={styles.selectimage} src={imagepath + allwords[3].image}>
                                        </img>}
                                </Stack>
                            </Button>
                        </Tooltip>}
                </Stack>
            </Stack>
            <FloatingButton onClick={showTranslations} ></FloatingButton>
        </Stack>
    )
}