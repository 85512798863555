import React from 'react';
import styles from './lessoncard.module.css';
import { Layers, Book, Forum, Videocam, Check } from '@mui/icons-material';

export default function LessonCard(props) {
    const lessonname = props.lessonname
    const lessontype = props.lessontype
    const isfinished = props.isfinished
    const LESSON_TYPE_SYMBOLS = [<Layers />, <Book />, <Forum />, <Videocam />];
    
    const row = props.row
    if (row == 0) {
    return(
        <div className={styles.lessoncard + " " + styles.lessoncard1}>
           {lessonname}
           <div className={styles.lessontypesymbol}>{isfinished ?  <Check /> : LESSON_TYPE_SYMBOLS[lessontype - 1]}</div>
        </div>
    )
    }
    if (row == 1) {
        return(
            <div className={styles.lessoncard + " " + styles.lessoncard2}>
               {lessonname}
               <div className={styles.lessontypesymbol}>{isfinished ?  <Check /> : LESSON_TYPE_SYMBOLS[lessontype - 1]}</div>
            </div>
        )
        }
    if (row == 2) {
        return(
            <div className={styles.lessoncard + " " + styles.lessoncard3}>
                {lessonname}
                <div className={styles.lessontypesymbol}>{isfinished ?  <Check /> : LESSON_TYPE_SYMBOLS[lessontype - 1]}</div>
            </div>
        )
        }
        if (row == 3) {
            return(
                <div className={styles.lessoncard + " " + styles.lessoncard4}>
                    {lessonname}
                    <div className={styles.lessontypesymbol}>{isfinished ?  <Check /> : LESSON_TYPE_SYMBOLS[lessontype - 1]}</div>
                </div>
            )
            }
}
