import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../util/authcontext";
import { Colors, BackGroundColors, LightColors } from '../util/constants';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './activation.module.css';
import { getFirestore, collection, getDocs, query, where, orderBy, setDoc, updateDoc, doc } from "firebase/firestore";
import firebaseApp from '../util/firebase';
import { useNavigate } from "react-router-dom";

export default function Activation() {
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();
    const { uservalue, languagevalue, loaded } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [textvalue, setTextValue] = useState("")
    const [result, setResult] = useState("")

    function handleTextChange(e) {
        setTextValue(e.target.value)
    }

    function registerActivationCode() {
        const code = textvalue.trim()

        if (code == "") {
            setResult("Lütfen kodu giriniz.")
        }
        else { 
            checkCode(code)
        }
    }

    const checkCode = async(code) => {
        const result = await fetchData(code)

        const currenttime = new Date().getTime()
        const activationdate = Math.floor(currenttime / 1000)

        if (result.length == 0) {
            setResult("Aktivasyon kodu bulunamadı.")
        }
        else {
            const activation = result[0]
            const userid = user?.user?.userid

            if (activation.userid !== "") {
                setResult("Kurs daha önce aktive edilmiş.")   
            }
            else if (activation.expirationdate && activation.expirationdate !== 0) {
                if (activationdate > activation.expirationdate) {
                    setResult("Kursun süresi dolduğundan aktivasyon yapılamadı.")  
                }
                else {
                    if (userid) {
                        await activate(code, userid, activationdate)
    
                        setResult("Kurs başarıyla aktive edildi.")
                        navigate("/");
                    }
                }
            }
            else {
                if (userid) {
                    await activate(code, userid, activationdate)

                    setResult("Kurs başarıyla aktive edildi.")
                    navigate("/");
                }
            }
        }
    }

    const updateResults = async(activationcode, languagecode, userid) => {
        const docRefProfile = doc(db, "users", userid, "profiles", languagecode);

        const results = await fetchResults(activationcode)

        if (results.length !== 0) {
            const finishedlessons = results[0].finishedlessons
            const percentage = results[0].percentage
            const vocabularycount = results[0].vocabularycount

            await setDoc(docRefProfile, {
                finishedlessons: finishedlessons,
                percentage: percentage,
                vocabulary: vocabularycount
            }, { merge: true })
        }
    }

    const fetchData = async (code) => {
        const documentname = "activationcodes"
        const q = query(collection(db, documentname), where("code", "==", code));
        const querySnapshot = await getDocs(q);
        const result = querySnapshot.docs.map(doc => doc.data());

        return result
     }

     const fetchResults = async (activationcode) => {
        const documentname = "results"
        const q = query(collection(db, documentname), where("activationcode", "==", activationcode));
        const querySnapshot = await getDocs(q);
        const results = querySnapshot.docs.map(doc => doc.data());

        return results
     }
     
     const activate = async(code, userid, activationdate) => {
        const expirationdate = activationdate + 15552000
        let languagecode = ""

        switch(code.substring(0,2)) {
            case "al":
                languagecode = "de"
                break
            case "in":
                languagecode = "en"
                break
            default:
                languagecode = "en"
                break
        }

        const docRef = doc(db, "activationcodes", code);
        const docRefUser = doc(db, "users", userid);

        await updateDoc(docRef, {
            activationdate: activationdate,
            active: true,
            code: code,
            expirationdate: expirationdate,
            userid: userid
        });

        await updateDoc(docRefUser, {
            expirationdate: expirationdate,
            languagecode: languagecode,
            premium: true,
            purchasechannel: "activation"
        });

        await updateResults(code, languagecode, userid)

        var updatedUser = user
        updatedUser.user.premium = true
        updatedUser.user.purchasechannel = "activation"
        updatedUser.user.languagecode = languagecode
        setLanguage({languagecode: languagecode, sourcelanguagecode: "tr"})
        setUser(updatedUser)
    }

    useEffect(() => {
        document.body.style.background = BackGroundColors[0]
    }, []);

    useEffect(() => {
        if (!user?.profile && loaded) {
            navigate("/login");
        }
    }, [user, loaded]);

    return(
        <>
        {user?.profile != null && <><NavBar languagecode={language?.languagecode} points={user?.profile?.points} percentage={user?.profile?.percentage} avatarurl={user?.authuser?.photoURL} /></>}
        <Stack spacing={10} sx={{textAlign: 'center', padding: 10, marginTop:'64px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Stack sx={{textAlign: 'left'}}>
        <h1>Online Set Kurs Aktivasyonu</h1>
        <h2>Henüz satın almadıysanız <a href="https://www.fono.com.tr">online mağazamızı</a> ziyaret ediniz.</h2>
        <TextField 
            id="outlined-basic"                 
            onChange={(e) => handleTextChange(e)}
            InputLabelProps={{style : {color : LightColors[1]} }} 
            label="Kutudan çıkan aktivasyon kodunu buraya giriniz." 
            variant="outlined" 
            sx={{ input: { color:  LightColors[1] }}}/>
        <p>Kodu başkasıyla paylaşmayınız.</p>
        </Stack>
        {result != "" && <h2>{result}</h2>}
        <Button className={styles.continuebutton} onClick={registerActivationCode} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: '100%', maxWidth: '500px', height: 50, ':hover': {
            bgcolor: LightColors[0]
          }}}>Gönder</Button>
        </Stack>
        </>
    )
}