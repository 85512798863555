import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Play from './play.js'
import Pause from './pause.js'
import { AUDIO_PATH } from '../util/constants';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import ContinueButton from './continuebutton';
import styles from './examples.module.css';

export default function Examples(props) {
  const exercisefields = props.exercisefields;
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;
  const audiopath = AUDIO_PATH + languagecode + '-fono/'

  const [explanation, setExplanation] = useState("")
  const [examples, setExamples] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(-1);
  const [correctaudio, setCorrectAudio] = useState();

  function increaseOrder() {
    let order = props.order;
    order++;
    props.onNextExercise(order);
    if (correctaudio != null) {
        correctaudio.play();
      }
}

  useEffect(() => {
    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
    }

    const examplefields = exercisefields.filter(field => field.type == 3 && field.translation !== "")
    const explanationfields = exercisefields.filter(x => x.translation === "")

    if (explanationfields.length > 0) {
      setExplanation(explanationfields[0].name)
    }    
    setExamples(examplefields)
  }, []);

  function play(index) {
    const audio = document.getElementById("audio" + index);

    setPlayingIndex(index)
    audio.play();

    audio.onended = function() {
        setPlayingIndex(-1)
    }
  }

  function pause(index) {
    const audio = document.getElementById("audio" + index);

    audio.pause();

    setPlayingIndex(-1)
  }

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        increaseOrder()
      }
    };

    // Add the event listener to the window or document
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [correctaudio]);

  return(
      <Box sx={{width: 500, padding: '10px', backgroundColor: 'transparent' }}>
        <Stack spacing={5} justifyContent="space-evenly" alignItems="center">
            {explanation !== "" ? <div dangerouslySetInnerHTML={{ __html: explanation }} /> : <h3>Şimdi örnekleri inceleyelim.</h3>}
            {examples.map((example, index) => (
                    <Example index={index} example={example} play={play} pause={pause} audiopath={audiopath} audio={example.audio} playingIndex={playingIndex} />
                    ))}
            <ContinueButton color={color} onClick={increaseOrder} />
          </Stack>
      </Box>
  )
}

const Example = (props) => {
    const index = props.index
    const name = props.example.name
    const translation = props.example.translation
    const play = props.play
    const pause = props.pause
    const audiopath = props.audiopath
    const audio = props.audio
    const playingIndex = props.playingIndex

    return(
        <Stack alignItems="center">
            <div className={styles.player}>
                <audio id={"audio" + index}>
                <source src={audiopath + audio} />
                Your browser does not support the <code>audio</code> element.
                </audio>
            </div>
            <Stack direction="row" alignItems="center">
            {audio !== "" && <div className={styles.audiocontrol}>
                {playingIndex == index ? 
                    <Pause handleClick={() => pause(index)} /> :
                    <Play handleClick={() => play(index)} />
                }
            </div>}
            <p><b>{name}</b></p>
            </Stack>
            <p>{translation}</p>
        </Stack>
    )
};