import Stack from '@mui/material/Stack';
import ContinueButton from './continuebutton';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import styles from './multiplechoice.module.css';
import Button from '@mui/material/Button';

export default function MultipleChoice(props) {
  const exercisefield = props.exercisefield
  const exercisefields = props.exercisefields.map(x => x.answer !== "" ? (x.answer === x.translation ? { name: x.translation, translation: x.name } : { name: x.answer, translation: x.translation }) : { name: x.name, translation: x.translation })
  const color = props.color;
  const languagecode = props.languagecode;
  const rightanswer = exercisefield.answer !== "" ? (exercisefield.answer === exercisefield.translation ? { name: exercisefield.translation, translation: exercisefield.name } : { name: exercisefield.answer, translation: exercisefield.translation }) : { name: exercisefield.name, translation: exercisefield.translation }
  const soundenabled = props.soundenabled;
  const exercisecounter = props.exercisecounter

  const [answers, setAnswers] = useState([...new Set(exercisefields)])
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [sentence, setSentence] = useState(exercisefield.name)
  const [iswrong, setIsWrong] = useState(false)
  const [wrongindex, setWrongIndex] = useState(-1);

  function increaseOrder(iswrong) {
    let order = props.order;
    order++;
    props.onNextExercise(order, iswrong, "fill");
  }

  function checkAnswer(index) {
    const field = answers[index]
    if (field.name == rightanswer.name || field.translation == rightanswer.translation) {
      if (sentence.includes("___")) {
        setSentence(sentence.replace("___", field))
      }
      if (correctaudio != null) {
        correctaudio.play();
      }
      setTimeout(function () {
        increaseOrder();
      }, 600);
    }
    else {
      setIsWrong(true)
      setWrongIndex(index);
      if (incorrectaudio != null) {
        incorrectaudio.play();
      }
      setTimeout(function () {
        increaseOrder(true);
      }, 600);
    }
  }

  function takeNElementsKeepOne(arr, n, elementToKeep) {
    const withoutElementToKeep = arr.filter((element) => element.name !== elementToKeep.name);
    const randomElements = withoutElementToKeep.slice(0, n - 1);
  
    return [elementToKeep, ...randomElements];
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  function getUniqueElementsByField(array, field) {
    const seenValues = new Set();
    return array.filter((item) => {
      const fieldValue = item[field];
      if (seenValues.has(fieldValue)) {
        return false;
      } else {
        seenValues.add(fieldValue);
        return true;
      }
    });
  }

  useEffect(() => {
    if (exercisefield.question != "") {
      setSentence(exercisefield.question)
    }
    else {
      if (exercisefield.answer !== "") {
        if (exercisefield.answer === exercisefield.translation) {
          setSentence(exercisefield.name)
        }
        else {
          setSentence(exercisefield.translation) 
        }
      }
      else {
        setSentence(exercisefield.translation)
      }
    }
    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
    }

    let finalanswers = takeNElementsKeepOne(answers, 6, rightanswer)
    let shuffledanswers = shuffle(finalanswers)
    const uniqueanswers = getUniqueElementsByField(shuffledanswers, 'name');
    setAnswers(uniqueanswers)

    return () => {
      setAnswers([])
      setSentence(exercisefield.name)
      setIsWrong(false)
      setWrongIndex(-1)
    }
  }, [exercisefield, exercisecounter]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '1') {
        checkAnswer(0)
      }
      if (event.key === '2') {
        checkAnswer(1)
      }
      if (event.key === '3') {
        checkAnswer(2)
      }
      if (event.key === '4') {
        checkAnswer(3)
      }
      if (event.key === '5') {
        checkAnswer(4)
      }
      if (event.key === '6') {
        checkAnswer(5)
      }
      if (event.key === '7') {
        checkAnswer(6)
      }
      if (event.key === '8') {
        checkAnswer(7)
      }
      if (event.key === '9') {
        checkAnswer(8)
      }
    };

    // Add the event listener to the window or document
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [answers, correctaudio]);

  return (
    <Stack spacing={15} alignItems="center">
      <Stack spacing={7} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <h2>Uygun cevabı seçin.</h2>
        <h3>{sentence}</h3>
      </Stack>
      <Stack spacing={2}>
        {answers.map((field, index) => (
          <Button key={"multibutton" + index} className={styles.multiplechoicebutton + (iswrong == true && index == wrongindex ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(index, e)} sx={{
            backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, ':hover': {
              bgcolor: color + '.light'
            }
          }}>{field.name}</Button>
        ))}
      </Stack>
    </Stack>
  )
}