export const Colors = ['#FFA570', '#A6D175', '#78D3E2', '#FD9598', '#4cc4d9', '#9ccc65', '#9e9d24', '#ffb300', '#f2c933', '#ff8965', '#ff7043', '#f4aca5', '#ef5350'];
export const DarkColors = ['#FF8B47', '#89C247', '#4CC3D9', '#FC7378', '#60b3d1'];
export const LightColors = ['#FFCEB1', '#d2afed'];
export const TintColor = 'rgba(0,0,0,0.5)';
export const OtherColors = ['#ffe7c5', '#ee5e5f', '#a3f4e2'];
export const OtherColorsDark = ['#c2ad97', '#ba4a4a', '#4df0cc'];
export const BackGroundColors = ["#424596", "#313485"];
export const Languages = [{code: 'en', name: 'İngilizce'}, {code: 'de', name: 'Almanca'}];
export const SourceLanguages = [{code: 'tr', name: 'Türkçe'}];
export const MAX_LIVES = 5;
export const LIVES_LOCK_S = 1800
export const INSTAGRAM_URL = "https://www.instagram.com/fonoyayinlari";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCvLNTmPcdN4fYGetF_gzRPg";
export const TIKTOK_URL = "https://www.tiktok.com/@fonoyayinlari";
export const BADGE_THRESHOLDS = [10.0, 40.0, 85.0];
export const AUDIO_PATH = "https://fonostorage.blob.core.windows.net/a-";
export const IMAGE_PATH = "https://fonostorage.blob.core.windows.net/aa-image-";
export const LEVELS = ["Başlangıç 1", "Başlangıç 2", "İleri Başlangıç 1", "İleri Başlangıç 2", "Orta 1", "Orta 2"];
export const TOTAL_POINTS = {en: 3540, de: 4752};

