import Button from '@mui/material/Button';
import styles from './checkbutton.module.css'

export default function CheckButton(props) {
    const color = props.color;
    const click = props.onClick;

    return(
        <Button className={styles.checkbutton} onClick={click} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 300, height: 50, ':hover': {
            bgcolor: color + '.light'
          }}}>Cevapla</Button>
    )
}