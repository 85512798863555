import Button from '@mui/material/Button';
import styles from './matchbutton.module.css';

export default function MatchButton(props) {
    const isRemoved = props.isremoved
    const color = props.color
    const field = props.field
    const iswrong = props.iswrong
    const direction = props.direction

    return(
        <Button disabled={isRemoved} className={styles.matchbutton + (iswrong == true ? " " + styles.wrong : "")} sx={{backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, ':hover': {
            bgcolor: color + '.light'
        }}}>{direction == 0 ? field.translation : field.name}</Button>
    )
}