import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { BackGroundColors, Colors, LightColors } from '../util/constants';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import styles from './login.module.css';
import { useNavigate } from "react-router-dom";
import AuthContext from "../util/authcontext";

const SCREEN = {
  SignIn: 'SignIn',
  SignUp: 'SignUp',
  ForgotPassword: 'ForgotPassword'
}

export default function Login() {
  const navigate = useNavigate();
  const auth = getAuth();

  const [emailvalue, setEmailValue] = useState("")
  const [passwordvalue, setPasswordValue] = useState("")
  const [loginScreen, setLoginScreen] = useState(SCREEN.SignIn)
  const [emailSent, setEmailSent] = useState(false)
  const [result, setResult] = useState("")
  const { uservalue, languagevalue, loaded, togglelogin } = useContext(AuthContext);
  const [login, setLogin] = togglelogin;

  function trySignIn() {
    const email = emailvalue
    const password = passwordvalue

    if (loginScreen === SCREEN.SignIn) {
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        setLogin(true);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.errorMessage;
        if (errorCode === "auth/user-not-found") {
          setResult("Kullanıcı bulunamadı")
        }
        else {
          setResult("Hata oluştu")
        }
      });
    }
    else if (loginScreen === SCREEN.SignUp) {
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        setLogin(true);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/email-already-in-use") {
          setResult("E-posta adresi kullanımda") 
        }
        else {
          setResult("Hata oluştu")
        }
      });
    }
  }

  function sendEmail() {
    const email = emailvalue

    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      setResult("") 
      setEmailSent(true)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      setResult(errorMessage)
    });
  }

  function toggleLoginScreen(screen) {
    setResult("") 
    setLoginScreen(screen)
  }

  function handleEmailChange(e) {
    setEmailValue(e.target.value)
  }

  function handlePasswordChange(e) {
    setPasswordValue(e.target.value)
  }

  useEffect(() => {    
    document.body.style.background = BackGroundColors[0]
  }, []);

  if (loginScreen === SCREEN.SignIn) {
  return (
    <Container sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
        <Stack spacing={5} sx={{width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h2 className={styles.logintitle}>İlerlemeni kaydetmek için giriş yap</h2>
          <h3 className={styles.loginsubtitle}>Hesabın yoksa <Button sx={{textTransform: 'none', fontSize: '0.95rem', fontWeight: 'bold'}} onClick={() => toggleLoginScreen(SCREEN.SignUp)} variant="text">üye ol</Button></h3>
          {result != "" && <h2>{result}</h2>}
        </Stack>
        <TextField
              id="textfield-email"
              value={emailvalue}
              onChange={(e) => handleEmailChange(e)}
              label="E-posta"
              sx={{width: '100%', input: { color: LightColors[1] }}}
            />
          <TextField
              id="textfield-password"
              value={passwordvalue}
              onChange={(e) => handlePasswordChange(e)}
              label="Şifre"
              sx={{width: '100%', input: { color: LightColors[1] }}}
            />
            <Button className={styles.continuebutton} onClick={trySignIn} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: '100%', maxWidth: '500px', height: 50, ':hover': {
            bgcolor: LightColors[0]
          }}}>Giriş yap</Button>
            <Button sx={{textTransform: 'none',color: 'black', fontSize: '0.95rem', fontWeight: 'bold'}} onClick={() => toggleLoginScreen(SCREEN.ForgotPassword)} variant="text">Şifremi unuttum</Button>
        </Stack>
    </Container>
  )}
  else if (loginScreen === SCREEN.SignUp) {
    return (
      <Container sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
          <Stack spacing={5} sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 className={styles.logintitle}>İlerlemeni kaydetmek için üye ol</h2>
            <h3 className={styles.loginsubtitle}>Hesabın varsa <Button sx={{textTransform: 'none', fontSize: '0.95rem', fontWeight: 'bold'}} onClick={() => toggleLoginScreen(SCREEN.SignIn)} variant="text">giriş yap</Button></h3>
            {result != "" && <h2>{result}</h2>}
          </Stack>
          <TextField
                id="textfield-email"
                value={emailvalue}
                onChange={(e) => handleEmailChange(e)}
                label="E-posta"
                sx={{width: '100%', input: { color: LightColors[1] }}}
              />
            <TextField
                id="textfield-password"
                value={passwordvalue}
                onChange={(e) => handlePasswordChange(e)}
                label="Şifre"
                sx={{width: '100%', input: { color: LightColors[1] }}}
              />
              <Button className={styles.continuebutton} onClick={trySignIn} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: '100%', maxWidth: '500px', height: 50, ':hover': {
              bgcolor: LightColors[0]
            }}}>Üye ol</Button>
          </Stack>
      </Container>
    )}
    else if (loginScreen === SCREEN.ForgotPassword) {
      if (emailSent){
        return(
          <Container sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
          <Stack spacing={5} sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 className={styles.logintitle}>E-posta gönderildi.</h2>
            {result != "" && <h2>{result}</h2>}
          </Stack>
           <Button sx={{textTransform: 'none', fontSize: '0.95rem', fontWeight: 'bold'}} onClick={() => toggleLoginScreen(SCREEN.SignIn)} variant="text">Giriş yap</Button>
          </Stack>
      </Container>
        )
      } 
      else {
        return (
        <Container sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
            <Stack spacing={5} sx={{ width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h2 className={styles.logintitle}>Şifre sıfırla</h2>
              <h3 className={styles.loginsubtitle}>Hatırladıysan <Button sx={{textTransform: 'none', fontSize: '0.95rem', fontWeight: 'bold'}} onClick={() => toggleLoginScreen(SCREEN.SignIn)} variant="text">giriş yap</Button></h3>
              {result != "" && <h2>{result}</h2>}
            </Stack>
            <TextField
                  id="textfield-email"
                  value={emailvalue}
                  onChange={(e) => handleEmailChange(e)}
                  label="E-posta"
                  sx={{width: '100%', input: { color: LightColors[1] }}}
                />
                <Button className={styles.continuebutton} onClick={sendEmail} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: '100%', maxWidth: '500px', height: 50, ':hover': {
                bgcolor: LightColors[0]
              }}}>E-posta gönder</Button>
            </Stack>
        </Container>
      )}
    }
}
