import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import VocabularyCard from './vocabularycard';
import ContinueButton from './continuebutton';
import correctsound from '../assets/sounds/correct.mp3';

export default function Vocabulary(props) {

    const color = props.color;
    const languagecode = props.languagecode;
    const exercisefield = props.exercisefield
    const word = {name: exercisefield.name, translation: exercisefield.translation, image: exercisefield.image, audio: exercisefield.audio}
    const [correctaudio, setCorrectAudio] = useState();
    const soundenabled = props.soundenabled;

    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
          }
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
        if (correctaudio != null) {
            correctaudio.play();
          }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            increaseOrder()
          }
        };
    
        // Add the event listener to the window or document
        window.addEventListener('keydown', handleKeyDown);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    return(
        <Stack spacing={10} sx={{width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h2>Çeviri için karta tıklayın.</h2>
            <VocabularyCard word={word} color={color} languagecode={languagecode} />
            <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
}