import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './dialogue.module.css';
import { Container } from '@mui/material';
import DialogueBox from './dialoguebox';
import Drawer from '@mui/material/Drawer';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';
import FillButton from './fillbutton';

export default function DialogueFill(props) {
  const exercisefields = props.exercisefields
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;

  const [fieldindex, setFieldIndex] = useState(0)
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [selectaudio, setSelectAudio] = useState();
  const [fields, setFields] = useState([]);
  const [currentfield, setCurrentField] = useState([]);
  const [answers, setAnswers] = useState([])
  const [rightanswers, setRightAnswers] = useState([])
  const [removedfields, setRemovedFields] = useState([])
  const [sentence, setSentence] = useState(null)
  const [wrongindex, setWrongIndex] = useState(-1)
  const [buttonindex, setButtonIndex] = useState(0)
  const [counter, setCounter] = useState(0)

  function increaseOrder() {
    let order = props.order;
    order++;
    props.onNextExercise(order, false, "dialoguefill");
  }

  function checkAnswer(index) {
    const answer = answers[index]
    if (removedfields.includes(index) == false) {
      if (answer == rightanswers[buttonindex]) {
        setSentence(sentence.replace("___", answer))
        let newremovedfields = removedfields
        let _ = newremovedfields.push(index)
        setRemovedFields(newremovedfields)
        if (buttonindex == answers.length - 1) {
          if (correctaudio != null) {
            correctaudio.play();
          }
          let dialoguecounter = counter + 2
          if (dialoguecounter >= fields.length - 1) {
            increaseOrder()
          }
          else {
            let _currentfield = exercisefields[fieldindex]
            setCurrentField(_currentfield)
            setRemovedFields([])
            setFieldIndex(dialoguecounter)
            setCounter(dialoguecounter)
            setButtonIndex(0)
          }
        }
        else {
          if (selectaudio != null) {
            selectaudio.play();
          }
          setButtonIndex(buttonindex + 1)
        }
      }
      else {
        setWrongIndex(index);
        if (buttonindex + 1 == answers.length) {
          if (incorrectaudio != null) {
            incorrectaudio.play();
          }
          setTimeout(function () {
            increaseOrder(true);
          }, 600);
        }
      }
    }
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  function splitTextIntoSentences(text) {
    // This regular expression matches sentence-ending punctuation and takes care of cases like abbreviations.
    const regex = /(?:\.|\?|\!)(?=\s|\w|$)|\n+/g;
    const sentences = text.split(regex);

    // Remove any empty sentences that might have been created by the split.
    const filteredSentences = sentences.filter(sentence => sentence.trim().length > 0);

    return filteredSentences;
  }

  function convertHtmlToText(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    return tempDiv.textContent || tempDiv.innerText || '';
  }

  useEffect(() => {
    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
      setSelectAudio(new Audio(selectsound));
    }

    let fields = exercisefields
    let sentence = ""
    let currentfield = {}

    if (fields.length == 1) {
      currentfield = fields[0]
      let plaintext = convertHtmlToText(currentfield.name)
      let sentences = splitTextIntoSentences(plaintext)
      let suffledsentences = shuffle(sentences)
      let randomindex = Math.floor(Math.random() * suffledsentences.length);
      sentence = suffledsentences[randomindex]
    }
    else {
      currentfield = fields[fieldindex + 1]
      sentence = currentfield.name
    }

    setFields(fields)
    setCurrentField(currentfield)

    let words = sentence.trim().split(" ").map(word => word.trim())

    const randomindex1 = Math.floor(Math.random() * words.length);
    const replacementvalue = "___";
    let rightanswers = []

    if (words.length === 1) {
      const _rightanswer1 = words[randomindex1]
      words[randomindex1] = replacementvalue;
      rightanswers = [_rightanswer1]
      setAnswers(rightanswers)
    }
    else {
      let randomindex2 = -1

      do {
        randomindex2 = Math.floor(Math.random() * words.length);
      } while (randomindex1 === randomindex2);
  
      const smallestrandom = Math.min(randomindex1, randomindex2)
      const biggestrandom = Math.max(randomindex1, randomindex2)
  
      const _rightanswer1 = words[smallestrandom]
      const _rightanswer2 = words[biggestrandom]
      words[randomindex1] = replacementvalue;
      words[randomindex2] = replacementvalue;
  
      rightanswers = [_rightanswer1, _rightanswer2]

      let answers = [_rightanswer1, _rightanswer2]
      let shuffledanswers = shuffle(answers)
      setAnswers(shuffledanswers)
    }

    const sentencetofill = words.join(" ")

    setSentence(sentencetofill)

    setRightAnswers(rightanswers)

    const element = document.getElementById('dialoguebox' + fieldindex);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [fieldindex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '1') {
        checkAnswer(0)
      }
      if (event.key === '2') {
        checkAnswer(1)
      }
    };

    // Add the event listener to the window or document
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [answers, fieldindex, buttonindex]);

  return (
    <Stack spacing={5} sx={{ paddingBottom: '450px' }}>
      <h3>Cümleyi tamamlayınız.</h3>

      <Container className={styles.dialoguecontainer}>
        <Stack spacing={3}>
          {fields.map((field, index) => (
            index <= fieldindex &&
            <div id={"dialoguebox" + index}>
              {exercisefields.length == 1 ? <DialogueBox color={color} direction={2} expression={field.name} translation={field.translation} />
                : <DialogueBox color={color} direction={index % 2} expression={field.name} translation={field.translation} />}
            </div>
          ))}
        </Stack>
      </Container>
      <Drawer className={styles.speechbox}
        hideBackdrop
        ModalProps={{ disableScrollLock: true }}
        anchor='bottom'
        open
        PaperProps={{
          style: {
            height: '30%',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      >
        <h2>{sentence}</h2>
        <h4>{exercisefields.length > 1 && currentfield.translation}</h4>
        <Stack direction="row" spacing={2}>
          {answers.map((answer, index) => (
            <div onClick={(e) => checkAnswer(index, e)}>
              <FillButton key={index} color={color} fieldname={answer} iswrong={index == wrongindex} isremoved={removedfields.includes(index)} />
            </div>
          ))}
        </Stack>
      </Drawer>
    </Stack>
  )
}