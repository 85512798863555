import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styles from './dialoguebox.module.css'
import React, { useState, useEffect } from 'react';

export default function DialogueBox(props) {
    const color = props.color
    const direction = props.direction
    const expression = props.expression
    const translation = props.translation

    const [showTranslation, setShowTranslation] = useState(false)

    function switchTranslation() { 
        setShowTranslation(!showTranslation)
    }

    if (direction == 0) {
        return(
            <Stack className={styles.left} direction='row' spacing={3} sx={{alignItems:'center'}}>
            <img className={styles.dialogueimage} src={require('../assets/icons/dialogueicon1.png')}>
                </img>
            <div className={styles.dialogueboxwrapper} onClick={switchTranslation}>
                <Box className={styles.dialoguebox} sx={{bgcolor: color + '.dark'}}>
                    <p>{showTranslation ? translation : expression}</p>
                </Box>
            </div>
            </Stack>
        )
    }

    else if (direction == 1) {
        return(
            <Stack className={styles.right} direction='row' spacing={3} sx={{alignItems:'center'}}>
            <div className={styles.dialogueboxwrapper} onClick={switchTranslation}>
                <Box className={styles.dialoguebox} sx={{bgcolor: color + '.dark'}}>
                    <p>{showTranslation ? translation : expression}</p>
                </Box>
            </div>
            <img className={styles.dialogueimage} src={require('../assets/icons/dialogueicon2.png')}>
                </img>
            </Stack>
        )
    }

    else {
        return(
            <Stack spacing={3} sx={{alignItems:'center'}}>
            <div className={styles.dialogueboxwrapper} onClick={switchTranslation}>
                <Box className={styles.textbox} sx={{bgcolor: color + '.dark'}}>
                    <div dangerouslySetInnerHTML={{ __html: showTranslation ? translation : expression }} />
                </Box>
            </div>
            </Stack>
        ) 
    }
}