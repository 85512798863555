import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import styles from './navbar.module.css';

const NavBar = (props) => {
  const pages = [{ name: 'Dersler', url: ''}, { name: 'Aktivasyon', url: 'activation'}];
  const pagesxs = [{ name: 'Dersler', url: ''}, { name: 'Aktivasyon', url: 'activation'}, { name: 'Profil', url: 'profile'}, { name: 'Ayarlar', url: 'settings'}];
  const settings = [{ name: 'Profil', url: 'profile'}, { name: 'Aktivasyon', url: 'activation'}, { name: 'Ayarlar', url: 'settings'}, { name: 'Yardım', url: 'help'}];
  const vocabulary = props.vocabulary ?? 0
  const percentage = props.percentage ? (props.percentage > 100.0 ? 100 : props.percentage.toFixed(1)) : 0.0

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const avatarurl = props.avatarurl;
  const languagecode = props.languagecode ? props.languagecode : "en";

  return (
    <AppBar position="sticky" style={{backgroundColor: 'transparent'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Tooltip title="Dil seç">
          <IconButton component={Link} to={'/language'} sx={{ p: 0 }}>
            <Avatar alt="Dil seç" src={require('../assets/flags/' + languagecode + 'flag.svg')} />
          </IconButton>
        </Tooltip>

        <Box sx={{ color: 'black', display: { xs: 'flex', md: 'none' } }}>
          <MenuItem sx={{ ml: 2 }} >
            <img src={require('../assets/icons/vocabularycount.png')} width="40" height="40">
            </img>
            <span className={styles.homemenustats}>{vocabulary}</span>
            </MenuItem>
            <MenuItem>
            <img src={require('../assets/icons/badgetraveler.png')} width="40" height="40">
            </img>
            <span className={styles.homemenustats}>{'%' + percentage}</span>
            </MenuItem>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pagesxs.map((page) => (
                <MenuItem key={page.name} component={Link} to={'/' + page.url} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                className={styles.menuitem}
                component={Link} to={'/' + page.url}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, mx: 4, display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem component={Link} to={'/' + setting.url} key={setting.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Tooltip title="Kelime adedi">
          <MenuItem sx={{ mr: 2 }} >
            <img src={require('../assets/icons/vocabularycount.png')} width="40" height="40">
            </img>
            <span className={styles.homemenustats}>{vocabulary}</span>
            </MenuItem>
            </Tooltip>
            <Tooltip title="Biten derslerin kursa yüzdesi">
            <MenuItem sx={{ mr: 2 }}>
            <img src={require('../assets/icons/badgetraveler.png')} width="40" height="40">
            </img>
            <span className={styles.homemenustats}>{'%' + percentage}</span>
            </MenuItem>
            </Tooltip>
            <Tooltip title="Profile">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src={avatarurl} />
          </IconButton>
        </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
