import React, { useState, useEffect } from 'react';

export default function CountDownTimer(props) {

    const time = props.time;
    const locktime = props.locktime
    const [timestring, setTimeString] = useState("");

    useEffect(() => {
        setTimeLeft();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft();
          }, 1000);

          return () => clearTimeout(timer);
    }, [timestring]);

    function setTimeLeft() {
        if (time === 0) {
            setTimeString(" ");
        }
        else {
            let currenttime = new Date().getTime()
            const currenttimeseconds = Math.floor(currenttime / 1000)
            const difference = (locktime - (currenttimeseconds - time))
            if (difference <= 0) {
                props.onFinish();
            }
            else {
                let hours = Math.floor(difference / (60 * 60));
                let minutes = Math.floor((difference / 60) % 60);
                let seconds = Math.floor((difference) % 60);
                if (hours < 10) { hours = '0' + hours;}
                if (minutes < 10) {minutes = '0' + minutes;}
                if (seconds < 10) {seconds = '0' + seconds;}
                setTimeString(hours + ':' + minutes + ':' + seconds);
            }
        }
    }

    return(
        <h1>{timestring}</h1>
    )
}