import Fab from '@mui/material/Fab';
import styles from './floatingbutton.module.css'
import TransformIcon from '@mui/icons-material/Transform';

const FloatingButton = (props) => {
    function onClick() {
        props.onClick()
    }

    return (
        <button onClick={onClick} className={styles.floatingbutton} color="primary" aria-label="add">
        </button>
    )
}

export default FloatingButton