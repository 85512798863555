import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBXnAbKbmHZUyGltkEeJaT9ogTJ0MmNNv0",
    authDomain: "fono-ddb87.firebaseapp.com",
    projectId: "fono-ddb87",
    storageBucket: "fono-ddb87.appspot.com",
    messagingSenderId: "263471508237",
    appId: "1:263471508237:web:ed48455a7c6f1f46c95e44",
    measurementId: "G-JSCPLYYP0L"
  };

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;