import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';
import FillButton from './fillbutton';
import TableComponent from './tablecomponent';

export default function Table(props) {
  const exercisefields = props.exercisefields
  const questions = props.exercisefields.map(x => x.answer === x.translation ? {name: x.name, translation: x.translation} : {name: x.translation, translation: x.name})
  const rightanswers = props.exercisefields.map(x => x.translation === x.answer ? x.answer : x.name)
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;

  const [answers, setAnswers] = useState(exercisefields)
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [selectaudio, setSelectAudio] = useState();
  const [removedfields, setRemovedFields] = useState([])
  const [filledfields, setFilledFields] = useState([])
  const [isShuffled, setIsShuffled] = useState(false)
  const [wrongindex, setWrongIndex] = useState(-1);
  const [fieldindex, setFieldIndex] = useState(0)

  function increaseOrder(iswrong) {
    let order = props.order;
    order++;
    props.onNextExercise(order, iswrong, "table");
  }

  function checkAnswer(index) {
    const field = answers[index]
 
    if (removedfields.includes(index) == false) {
      if (field.translation === questions[fieldindex].name || field.translation === questions[fieldindex].translation ||field.name === questions[fieldindex].translation) {
        let newremovedfields = removedfields
        let _ = newremovedfields.push(index)
        setRemovedFields(newremovedfields)
        let newfilledfields = filledfields
        let __ = newfilledfields.push(fieldindex)
        setFilledFields(newfilledfields)
        if (fieldindex + 1 == rightanswers.length) {
          if (correctaudio != null) {
            correctaudio.play();
          }
          setTimeout(function () {
            increaseOrder();
          }, 600);
        }
        else {
          if (selectaudio != null) {
            selectaudio.play();
          }
        }
        setFieldIndex(fieldindex + 1)
      }
      else {
        setWrongIndex(index);
        if (fieldindex + 1 == rightanswers.length) {
          if (incorrectaudio != null) {
            incorrectaudio.play();
          }
          setTimeout(function () {
            increaseOrder(true);
          }, 600);
        }
      }
    }
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  useEffect(() => {
    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
      setSelectAudio(new Audio(selectsound));
    }

    if (isShuffled == false) {
      let shuffledanswers = shuffle(JSON.parse(JSON.stringify(answers)))

      setAnswers(shuffledanswers)
      setIsShuffled(true)
    }

  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '1') {
        checkAnswer(0)
      }
      if (event.key === '2') {
        checkAnswer(1)
      }
      if (event.key === '3') {
        checkAnswer(2)
      }
      if (event.key === '4') {
        checkAnswer(3)
      }
      if (event.key === '5') {
        checkAnswer(4)
      }
      if (event.key === '6') {
        checkAnswer(5)
      }
      if (event.key === '7') {
        checkAnswer(6)
      }
      if (event.key === '8') {
        checkAnswer(7)
      }
      if (event.key === '9') {
        checkAnswer(8)
      }
    };

    // Add the event listener to the window or document
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [answers, fieldindex]);

  return (
    <Stack spacing={15} sx={{paddingBottom: 5}}>
      <h2>Tabloyu tamamlayın.</h2>
      <TableComponent questions={questions} fieldindex={fieldindex} rightanswers={rightanswers} filledfields={filledfields} />
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {answers.map((field, index) => (
          <div key={"filldiv" + index} onClick={(e) => checkAnswer(index, e)}>
            <FillButton key={index} iswrong={index == wrongindex} color={color} fieldname={field.answer === field.translation ? field.answer : field.name} isremoved={removedfields.includes(index)} />
          </div>
        ))}
      </Stack>
    </Stack>
  )
}

