import LessonGrid from '../components/lessongrid'
import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../util/authcontext";
import firebaseApp from '../util/firebase';
import { Colors, BackGroundColors } from '../util/constants';
import { collection, getDocs, query, orderBy, getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function Home() {

    const navigate = useNavigate();
    const { uservalue, languagevalue, loaded, togglelogin } = useContext(AuthContext);
    const [user, setUser] = uservalue
    const [language, setLanguage] = languagevalue
    const [login, setLogin] = togglelogin;
    const [sections, setSections] = useState([]); 
    const [lessons, setLessons] = useState([]);
    const [finishedlessons, setFinishedLessons] = useState([]);
    const db = getFirestore(firebaseApp);

    const fetchData = async () => {
        const sectionsdocumentname = "sections" + language.languagecode + language.sourcelanguagecode
        const lessonsdocumentname = "lessons" + language.languagecode + language.sourcelanguagecode
        const q1 = query(collection(db, sectionsdocumentname), orderBy("order"));
        const q2 = query(collection(db, lessonsdocumentname));
        const querySnapshot1 = await getDocs(q1);
        const querySnapshot2 = await getDocs(q2);
        const sections = querySnapshot1.docs.map(doc => ({id: doc.id, data: doc.data()}));
        const lessons = querySnapshot2.docs.map(doc => ({id: doc.id, data: doc.data()}));
        setSections(sections); 
        setLessons(lessons);  
     }

    useEffect(() => {
        document.body.style.background = BackGroundColors[0]
    }, []);

    useEffect(() => {
        if (!user?.profile && loaded && !login) {
            navigate("/login");
        }
        else if (user?.profile && loaded) {
            fetchData();
            setFinishedLessons(user?.profile?.finishedlessons)
        }
    }, [user, loaded, login]);

    return(
        <>
        {user?.profile && loaded && <><NavBar languagecode={language?.languagecode} vocabulary={user?.profile?.vocabulary} percentage={user?.profile?.percentage} avatarurl={user?.authuser?.photoURL} />
        {sections.length > 0 && lessons.length > 0 && <LessonGrid sections={sections} lessons={lessons} finishedlessons={finishedlessons} url={'/game'} colors={Colors} />}</>}
        </>
    )
}