import React from "react";
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import styles from './pause.module.css';

export default function Play(props) {
  const { handleClick } = props;

  return (
    <button className={styles.pausebutton} onClick={() => handleClick()}>
      <PauseCircleFilledIcon />
    </button>
  );
}
