import Button from '@mui/material/Button';
import styles from './fillbutton.module.css';

export default function FillButton(props) {
    const isRemoved = props.isremoved
    const color = props.color
    const fieldname = props.fieldname
    const iswrong = props.iswrong

    return(
        <Button disabled={isRemoved} className={styles.fillbutton + (iswrong == true ? " " + styles.wrong : "")} sx={{backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, ':hover': {
            bgcolor: color + '.light'
        }}}>{fieldname}</Button>
    )
}