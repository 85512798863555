import Stack from '@mui/material/Stack';
import React, { useState, useEffect, useRef } from 'react';
import styles from './dialogue.module.css';
import { Container } from '@mui/material';
import DialogueBox from './dialoguebox';
import Play from './play.js'
import Pause from './pause.js'
import { AUDIO_PATH } from '../util/constants';
import ContinueButton from './continuebutton';

export default function Dialogue(props){
    const exercisefields = props.exercisefields
    const languagecode = props.languagecode;
    const tracks = exercisefields.map(x => AUDIO_PATH + languagecode + "-fono/" + x.audio)
    const color = props.color;
    const soundenabled = props.soundenabled;

    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const track = tracks[currentTrackIndex];

    const [correctaudio, setCorrectAudio] = useState();
    const [audio, setAudio] = useState(0);

    function increaseOrder() {
      let order = props.order;
      order++;
      props.onNextExercise(order, false, "dialogue");
    }

    const onEnded = () => {
      if (currentTrackIndex < tracks.length - 1) {
        setCurrentTrackIndex(currentTrackIndex + 1);
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
        setCurrentTrackIndex(0);
      }
    };

    useEffect(() => {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }, [isPlaying, currentTrackIndex]);
  
    useEffect(() => {
      const instance = audioRef.current;
  
      instance.addEventListener('ended', onEnded);
  
      return () => {
        instance.removeEventListener('ended', onEnded);
      };
    }, [currentTrackIndex, tracks]);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          increaseOrder()
        }
      };
  
      // Add the event listener to the window or document
      window.addEventListener('keydown', handleKeyDown);
  
      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [correctaudio]);

    return (
        <Stack sx={{ alignItems: 'center', paddingBottom: 10}} spacing={10}>
            <h3>Dinlemek için oynat butonuna, çeviri için kutulara tıklayınız.</h3>
            <audio ref={audioRef} src={track} />
            <div className={styles.audiocontrol}>
              {isPlaying ? 
                <Pause handleClick={() => setIsPlaying(false)} /> :
                <Play handleClick={() => setIsPlaying(true)} />
              }
            </div>
            <Container className={styles.dialoguecontainer}>
            <Stack spacing={3}>
            {exercisefields.map((field, index) => 
            exercisefields.length == 1 ? <DialogueBox color={color} direction={2} expression={field.name} translation={field.translation} /> 
                : <DialogueBox color={color} direction={index % 2} expression={field.name} translation={field.translation} /> 
            )}
            </Stack>
            </Container>
            <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
}