import * as React from "react";
import "./App.css";
import AuthContextProvider from './util/authcontextprovider';
import { useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import 'video.js/dist/video-js.css';

function App() {
  const [user, setUser] = useState({});
  const [language, setLanguage] = useState({});
  // const uservalue = useMemo(
  //   () => ({ user, setUser }),
  //   [user]
  // );
  // const languagevalue = useMemo(
  //   () => ({ language, setLanguage }),
  //   [language]
  // );

  return (
    <AuthContextProvider value={{uservalue: [user, setUser], languagevalue: [language, setLanguage], loaded: false}}>
      <div className="App">
      </div><Outlet />
    </AuthContextProvider>
  );
}

export default App;
