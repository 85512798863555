import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './vocabularycard.module.css';
import Play from './play.js'
import Pause from './pause.js'
import { IMAGE_PATH, AUDIO_PATH } from '../util/constants';

export default function VocabularyCard(props) {
    const word = props.word;
    const color = props.color;
    const languagecode = props.languagecode;
    const audiopath = AUDIO_PATH + languagecode + '-fono/'
  
    const [flipped, setFlipped] = useState(false);

    useEffect(() => {

    }, []);

    function flip() {
      setFlipped(!flipped);
    }
    
    return (
    <div onClick={flip} className={styles.cardcontainer + (flipped ? " " + styles.flipped : "")}>
        <Front word={word} color={color} languagecode={languagecode} audio={audiopath} />
        <Back word={word} color={color} />
    </div>

    )
}
  
  function Front(props) {
    const word = props.word;
    const color = props.color;
    const languagecode = props.languagecode;
    const imagepath = IMAGE_PATH + languagecode + "-fono/"
    const audiopath = props.audio + word.audio 

    const [isPlaying, setIsPlaying] = useState(false);

    function play() {
      const audio = document.getElementById("audio");
  
      audio.play();

      audio.onended = function() {
        setIsPlaying(false)
      }
    }
  
    function pause() {
      const audio = document.getElementById("audio");
  
      audio.pause()

      setIsPlaying(false)
    }

      return (
        <Box className={styles.front} sx={{
            borderRadius: 1,
            backgroundColor: color + '.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
        <Stack sx={{ alignItems: 'center' }} spacing={6}>
        <img className={styles.wordimage} src={imagepath + word.image}>
                        </img>
            <div>
            <audio id="audio">
            <source src={audiopath} />
            Your browser does not support the <code>audio</code> element.
            </audio>
            </div>
            <Stack direction="row" alignItems="center">
            <div className={styles.audiocontrol}>
                {isPlaying == true ? 
                    <Pause handleClick={() => pause()} /> :
                    <Play handleClick={() => play()} />
                }
            </div>
            </Stack>
        <h2>{word.name}</h2>
        </Stack>
        </Box>
      )
  }
  
  function Back(props) {
    const word = props.word;
    const color = props.color;

      return (
        <Box className={styles.back} sx={{
            height: 450,
            width: 500,
            borderRadius: 1,
            backgroundColor: color + '.dark'
          }}>
            <h2>{word.translation}</h2>
        </Box>
      )
  }
  

  