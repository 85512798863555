import React, { useRef, createRef, useState, useEffect, useContext } from 'react';
import styles from './lessongrid.module.css';
import LessonCard from './lessoncard.js'
import { Link } from "react-router-dom";
import { LEVELS } from '../util/constants';

export default function LessonGrid(props) {
    const sections = props.sections;
    const lessons = props.lessons;
    const finishedlessons = props.finishedlessons;
    const url = props.url;
    const refs = useRef({})

    function LessonRow(props) {
        const section = props.section
        const lessons = props.lessons
        const index = props.index
        let sectionlessons = lessons.filter(x => section.data.lessons.includes(x.data.id))
        sectionlessons.sort((a, b) => a.data.order - b.data.order)

        return (
            sectionlessons.map((lesson, lessonindex) => (
                <Link key={"link" + lesson.id} className={styles.lessonlink} to={url} state={{ lesson: lesson.data, lessontype: section.data.lessontypes[lessonindex] }}>
                    <div ref={el => (refs.current = {...refs.current, [lesson.data.id]: el })}>
                        <LessonCard key={"lesson" + lesson.id} lessonname={"Ders " + (lessonindex + 1)} lessontype={section.data.lessontypes[lessonindex]} row={index % 4} isfinished={finishedlessons.includes(lesson.data.id)}></LessonCard>
                    </div>
                </Link>
            ))
        )
    }

    useEffect(() => {
        let arrlength = finishedlessons.length
        if (arrlength > 0) {
            const currentRef = refs.current[finishedlessons[arrlength - 1]];
            if (currentRef) {
                currentRef.scrollIntoView({ behavior: 'instant', block: 'center' });
            }
        }
    }, []);

    return (
        <div className={styles.lessongrid}>
            {sections.length > 0 && LEVELS.map((levelname, levelindex) => (
                <div key={"leveldiv" + levelindex}>
                    <h1 key={"level" + levelindex}>{levelname}</h1>
                    {sections.filter(x => x.data.level == levelindex + 1).map((section, index) => (
                        section.data.name &&
                        <div key={"sectionwrapper" + index}>
                            <h2 key={index} className={styles.sectiontitle}>{section.data.name}</h2>
                            <div key={"sectiondiv" + index} className={styles.hs}>
                                <LessonRow key={"section" + index} className={styles.lessonrow} section={section} lessons={lessons} index={index} />
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}