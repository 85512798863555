import styles from './tablecomponent.module.css';

export default function TableComponent(props) {
    const questions = props.questions
    const fieldindex = props.fieldindex
    const filledfields = props.filledfields
    const rightanswers = props.rightanswers

    return(
        <table className={styles.fillintable}>
            <tbody>
            {questions.map((field, index) => {
                return (
                    <tr key={index}>
                    <td>{field.name}</td>
                    {index == fieldindex && filledfields.includes(index) == false && <td><div className={styles.blink_me}>_____</div></td>}
                    {index < fieldindex && filledfields.includes(index) == true && <td><div>{rightanswers[index]}</div></td>}
                    {index != fieldindex && filledfields.includes(index) == false && <td><div>_____</div></td>}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}