import Stack from '@mui/material/Stack';
import ContinueButton from './continuebutton';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import FillButton from './fillbutton';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function Fill(props) {
  const exercisefield = props.exercisefield
  const exercisefields = props.exercisefields.map(x =>  ({name: x.name, translation: x.translation}))
  const color = props.color;
  const soundenabled = props.soundenabled;
  const isaudioexercise = exercisefield.audioexercise
  const exercisecounter = props.exercisecounter
  const languagecode = props.languagecode;

  const [rightanswer, setRightAnswer] = useState({})
  const [answers, setAnswers] = useState([])
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [sentence, setSentence] = useState("")
  const [removedfields, setRemovedFields] = useState([])
  const [wrongindex, setWrongIndex] = useState(-1);

  function increaseOrder(iswrong) {
    let order = props.order;
    order++;
    props.onNextExercise(order, iswrong, "fill");
  }

  function checkAnswer(index) {
    const field = answers[index]
    if (removedfields.includes(index) == false) {
      if (rightanswer == field) {
        setSentence(sentence.replace("___", field))
        let newremovedfields = removedfields
        let _ = newremovedfields.push(index)
        setRemovedFields(newremovedfields)
        if (correctaudio != null) {
          correctaudio.play();
        }
        setTimeout(function () {
          increaseOrder();
        }, 600);
      }
      else {
        setWrongIndex(index);
        if (incorrectaudio != null) {
          incorrectaudio.play();
        }
        setTimeout(function () {
          increaseOrder(true);
        }, 600);
      }
    }
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  function takeNElementsKeepOne(arr, n, elementToKeep) {
    const withoutElementToKeep = arr.filter((element) => element !== elementToKeep);
    const randomElements = withoutElementToKeep.slice(0, n - 1);
  
    return [elementToKeep, ...randomElements];
  }

  useEffect(() => {
    let _rightanswer = ""
    let words = []
    let randomindex = 0
    const replacementvalue = "___";

    words = exercisefield.name.split(" ")

    if (words.length > 2) {
      switch (languagecode) {
        default: 
          randomindex = Math.floor(Math.random() * words.length);
          break
        case "en": 
          if (["He", "She", "It"].includes(words[0]) === true) {
            randomindex = Math.floor((Math.random() * (words.length - 1)) + 1);
          }
        case "de": 
          if (["Er", "Sie", "Es"].includes(words[0]) === true) {
            randomindex = Math.floor((Math.random() * (words.length - 1)) + 1); 
          }
      }
    }

    _rightanswer = words[randomindex]

    setRightAnswer(_rightanswer)

    words[randomindex] = replacementvalue;

    const sentencetofill = words.join(" ")

    if (exercisefield.question !== "") {
      setSentence(exercisefield.question)
    }
    else {
      setSentence(sentencetofill)
    }

    // Map over the array of strings and split each string into an array of words
    let wordsarray = exercisefields.filter(x => !(x.name !== exercisefield.name && x.translation === exercisefield.translation)).map(y => y.name.split(' '));
    wordsarray = takeNElementsKeepOne(wordsarray, 6, _rightanswer)

    // The resulting array of arrays can be flattened into a single array of words
    let answersarray = [].concat.apply([], wordsarray);

    const n = Math.min(6, answersarray.length)
    const randomElements = [];
    const allanswers = [...new Set(answersarray)]
    while (randomElements.length < n - 1 && allanswers.length > 1) {
      const randomIndex = Math.floor(Math.random() * allanswers.length);
      const randomElement = allanswers.splice(randomIndex, 1)[0];

      if (randomElement !== _rightanswer) {
        randomElements.push(randomElement);
      }
    }

    // Final array with picked element and n-1 random elements
    const answers = [_rightanswer, ...randomElements];
    let shuffledanswers = shuffle(answers)
    setAnswers(shuffledanswers)

    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
    }

    return () => {
      setWrongIndex(-1)
      setRemovedFields([])
    };

  }, [exercisecounter]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === '1') {
        checkAnswer(0)
      }
      if (event.key === '2') {
        checkAnswer(1)
      }
      if (event.key === '3') {
        checkAnswer(2)
      }
      if (event.key === '4') {
        checkAnswer(3)
      }
      if (event.key === '5') {
        checkAnswer(4)
      }
      if (event.key === '6') {
        checkAnswer(5)
      }
      if (event.key === '7') {
        checkAnswer(6)
      }
      if (event.key === '8') {
        checkAnswer(7)
      }
      if (event.key === '9') {
        checkAnswer(8)
      }
    };

    // Add the event listener to the window or document
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [answers]);

  return (
    <Stack spacing={10}>
      <Stack spacing={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <h2>Boşluğu tamamlayın.</h2>
        {isaudioexercise &&
          <IconButton size="large">
            <PlayCircleIcon fontSize="large" />
          </IconButton>
        }
        <h2>{sentence}</h2>
        <h3>{exercisefield.translation}</h3>
        <h3>{exercisefield.audioexercise == false ? exercisefield.translation : ""}</h3>
      </Stack>
      <Stack direction="row" spacing={2}>
        {answers.map((field, index) => (
          <div key={"filldiv" + index} onClick={(e) => checkAnswer(index, e)}>
            <FillButton key={index} iswrong={index == wrongindex} color={color} fieldname={field} isremoved={removedfields.includes(index)} />
          </div>
        ))}
      </Stack>
    </Stack>
  )
}